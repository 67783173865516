.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 70vh;
}

.title {
    font-size: 2em;
}

.nextButton {
    font-size: 1.2em;
    color: white;
    border: none;
    width: 5em;
    padding: .4rem .6rem;
    border-radius: 8px;
    background-color: cornflowerblue;
    box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%);
}