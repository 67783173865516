.container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100vw;
    height: 100vh;
    background-color: black;
    padding: 1em;
    box-sizing: border-box;
    /* overflow: hidden; */
}

.title {
    font-size: 2em;
    align-self: center;
    text-align: center;
    color: #1755c4;
    font-weight: bold;
    margin: 0.5em 0 1em 0;
}

.videosContainer {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    height: calc(100% - 14em);
}

.video {
    display: flex;
    height: 100%;
    flex: 1;
    align-content: center;
    justify-content: center;
}

.videosContainer video {
    object-fit: contain;
    width: 100%;
    height: 100%;
}

.hangUpBtn {
    align-self: center;
    background-color: red;
    width: 4em;
    height: 4em;
    box-sizing: border-box;
    text-align: center;
    font-size: 1em;
    margin: 1em 0;
    border-radius: 100px;
}

.canvas {
    display: none;
}