body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  outline: none;
}

button {
  font-size: 1.1em;
  color: white;
  border: none;
  padding: .4rem .6rem;
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%);
  background-color: cornflowerblue;
  cursor: pointer;
  transition: all 0.1s linear;
}

button:hover {
  background-color: #1755c4;
}

input {
  border-radius: 8px;
  border: 1px solid #ddd;
  padding: .5rem;
  color: #666;
  box-shadow: inset 0 0 0.25rem #ddd;
  transition: all 0.2s linear;
}

input:focus {
  border: 1px solid #d0d0d0;
  box-shadow: inset 0 0 0.5rem #d0d0d0;
}